import React, { useEffect, useState } from "react";
import ContentContainer from "../../atoms/ContentContainer";
import TablePrimary from "../../atoms/Table/TablePrimary";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ConfirmationDialog from "../../atoms/Dialogs/ConfirmationDialog";
import FeedbackDialog from "../../atoms/Dialogs/FeedbackDialog";
import axios from "../../config/axios";


function ListTaxProfiles() {
  const history = useHistory();
  const [initLoading, setInitLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [taxProfiles, setTaxProfiles] = useState([]);
  const [stagedItemForDelete, setStagedItemForDelete] = useState(null);
  const [deleteSuccess, setDeleteSuccess] = useState(true);
  const [deleteFeedbackDialogOpen, setDeleteFeedbackDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    fetchTaxProfiles();
  }, [])

  const handleStageDelete = (pageItem) => {
    setDeleteDialogOpen(true);
    setStagedItemForDelete(pageItem)
  }
  const handleDelete = () => {
    setDeleteDialogOpen(false);
    setDeleteFeedbackDialogOpen(true);
  }
  const fetchTaxProfiles = async () => {
    const res = await axios.get('/v1/tax-profile/list');
    setTaxProfiles(res.data.data);
  }
  const columns = [
    { id: "name", label: "Name" },
    {
      id: "Action",
      label: "Action",
      render: (rowData) => (
        <div className="d-flex gap-2 justify-content-end">
          <IconButton
            onClick={() => history.push(`/custom-page/edit/${rowData.id}`)}
          >
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleStageDelete(rowData)} color="error">
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  return (
    <ContentContainer
      heading={"Tax Profiles"}
      _headingRightActionComponent={
        <button
          className="btn btn-primary border-0"
          style={{ backgroundColor: "#FFA382" }}
          onClick={() => {
            history.push("/tax-profile/create");
          }}
        >
          <i className="fas fa-plus-circle"></i>
          Create
        </button>
      }
    >
      <TablePrimary
        columns={columns}
        rows={taxProfiles}
        isLoading={initLoading}
        emptyMessage={{
          heading: "No Tax Profiles Found!",
          description:
            "Looks like you haven't created any tax profiles yet. Start creating custom tax profiles now to manage taxes for your delivery & sales!",
        }}
      />
      <ConfirmationDialog 
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        title={`Deleting Page`}
        description={`Are you sure you want to delete page ${stagedItemForDelete?.name}?`}
        onConfirm={handleDelete}
      />
      <FeedbackDialog 
        open={deleteFeedbackDialogOpen}
        setOpen={setDeleteFeedbackDialogOpen}
        isSuccess={deleteSuccess}
        successData={{
          title: "Successfully Delete",
          description: `Page ${stagedItemForDelete?.name} is deleted successfully`
        }}
        failedData={{
          title: "Failed to Delete",
          description: `Page ${stagedItemForDelete?.name} has failed to delete, please check your internet connection and reload the page then try again. If it still doesn't work try re logging in.`
        }}
      />
    </ContentContainer>
  );
}

export default ListTaxProfiles;
