import React from "react";
import ContentContainer from "../../atoms/ContentContainer";
import InputPrimary from "../../atoms/InputPrimary";
import {
  Alert,
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Switch,
} from "@mui/material";
// import CustomSelectPrimary from "../../atoms/CustomSelectPrimary/CustomSelectPrimary";
// import MultipleSelectPrimary from "../../atoms/MultipleSelectPrimary";
import { useState } from "react";
import MultipleSelect from "../../atoms/MultiSelectMui";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import { generateRandomString } from "../../helpers/utils";
import axios from "../../config/axios";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function TaxProfileEditor() {
  const [input, setInput] = useState({
    _id: null,
    name: "",
    description: "",
    isApplicableToSpecificCategories: true,
    selectedCategories: [],
    taxes: [
      {
        taxName: "Default",
        taxRate: 10,
        externalId: generateRandomString(6),
        compoundTaxReferences: [],
        allowedReferences: [],
      },
    ],
  });
  const history = useHistory();
  const [submitLoading, setSubmiLoading] = useState(false);
  const [feedback, setFeedback] = useState({
    isSuccess: true,
    isOpen: false,
    message: "",
  });
  const handleInputChange = (key, value) => {
    setInput({
      ...input,
      [key]: value,
    });
  };
  const handleAddCharge = () => {
    const newTaxReference = {
      taxName: "",
      taxRate: 0,
      externalId: generateRandomString(6),
      compoundTaxReferences: [],
      allowedReferences: getAllowedReferencesForTaxProfile(),
    };
    const updatedTaxes = input.taxes.map((tax) => ({
      ...tax,
      allowedReferences: [...tax.allowedReferences, newTaxReference],
    }));
    const newInput = {
      ...input,
      taxes: [...updatedTaxes, newTaxReference],
    };

    setInput(newInput);
  };

  const getAllowedReferencesForTaxProfile = (externalId) => {
    const _id = externalId ?? null;
    return input.taxes.filter(
      (tax) => tax.compoundTaxReferences.length === 0 && tax.externalId !== _id
    );
  };

  const handleUpdateTaxProfileData = (id, key, value) => {
    var newInput = input;
    if (key === "compoundTaxReferences") {
      const isEmptyReference = value.length === 0;
      newInput.taxes = newInput.taxes.map((tax) => {
        if (tax.externalId === id) return tax;
        const newCompoundTaxReference =
          tax.compoundTaxReferences.find((ref) => ref === id) &&
          !isEmptyReference
            ? tax.compoundTaxReferences.filter((ref) => ref !== id)
            : tax.compoundTaxReferences;
        const newAllowedReferences =
          tax.allowedReferences.find((ref) => ref.externalId === id) &&
          !isEmptyReference
            ? tax.compoundTaxReferences.filter((ref) => ref.externalId !== id)
            : tax.compoundTaxReferences;

        return {
          ...tax,
          compoundTaxReferences: newCompoundTaxReference,
          allowedReferences: newAllowedReferences,
        };
      });
    }
    console.log("New input", newInput);
    const _input = {
      ...newInput,
      taxes: newInput.taxes.map((t) => {
        if (t.externalId === id) {
          return {
            ...t,
            [key]: value,
          };
        }
        return {
          ...t,
          allowedReferences: t.allowedReferences.map((ref) => {
            if (ref.externalId === id) {
              return { ...ref, [key]: value };
            }
            return ref;
          }),
        };
      }),
    };
    console.log("Final input", _input);
    setInput(_input);
  };

  const handleSubmit = async () => {
    const validationErrors = [];
    if (!input.name) {
      validationErrors.push("Name field is required");
    }
    if (
      input.isApplicableToSpecificCategories &&
      input.selectedCategories.length === 0
    ) {
      validationErrors.push(
        "If applicable to specific categories selected, atleast one category must be selected"
      );
    }

    if (validationErrors.length !== 0) {
      setFeedback({
        isSuccess: false,
        isOpen: true,
        message: (
          <ul>
            {validationErrors.map((err) => (
              <li>{err}</li>
            ))}
          </ul>
        ),
      });
      return;
    }
    const body = {
      ...input,
      categoryIds: input.selectedCategories,
    };
    setSubmiLoading(true);
    try {
      await axios.post(`v1/tax-profile/create`, body);
      setFeedback({
        isSuccess: true,
        isOpen: true,
        message: "Tax created successfully",
      });
      setTimeout(() => {
        history.push('/tax-profile')
      }, 2000)
    } catch (error) {
      setFeedback({
        isSuccess: false,
        isOpen: true,
        message: "Failed to create tax profile",
      });
    }
    setSubmiLoading(false);
  };

  return (
    <ContentContainer heading={"Tax Profile Editor"}>
      <Grid container spacing={3}>
        {feedback.isOpen && (
          <Grid item xs={12}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setFeedback({
                      ...feedback,
                      isOpen: false,
                    });
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity={feedback.isSuccess ? "success" : "error"}
            >
              {feedback.message}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <InputPrimary
            value={input.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
            placeholder="Unique tax profile name"
            label={"Profile Name"}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          {input.isApplicableToSpecificCategories && (
            <MultipleSelect
              inputLabel={"Input Label"}
              label={"Applicable Categories"}
              options={[
                { value: "1", label: "Cagegory 1" },
                { value: "2", label: "Cagegory 2" },
              ]}
              selected={input.applicableCategories}
              setSelected={(val) =>
                handleInputChange("applicableCategories", val)
              }
            />
          )}
          <div style={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Switch
              checked={input.isApplicableToSpecificCategories}
              onChange={(e) =>
                handleInputChange(
                  "isApplicableToSpecificCategories",
                  e.target.checked
                )
              }
            />
            <span>Applicable to specific categories?</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <InputPrimary
            value={input.description}
            onChange={(e) => handleInputChange("description", e.target.value)}
            placeholder="Describe the purpose of this tax profile"
            label={"Description"}
            mutiline={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 15,
            }}
          >
            <h6 style={{ color: "black" }}>Tax Structure</h6>
            <Button variant="contained" onClick={handleAddCharge}>
              Add Charge
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Charge Name</TableCell>
                  <TableCell>Tax Rate (%)</TableCell>
                  <TableCell>Compound Tax Reference</TableCell>
                  <TableCell width={50}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {input.taxes.map((tax, i) => (
                  <TableRow key={tax.externalId}>
                    <TableCell>
                      <InputPrimary
                        value={tax.taxName}
                        onChange={(e) =>
                          handleUpdateTaxProfileData(
                            tax.externalId,
                            "taxName",
                            e.target.value
                          )
                        }
                        placeholder={"Name of tax charge"}
                      />
                    </TableCell>
                    <TableCell>
                      <InputPrimary
                        value={tax.taxRate}
                        onChange={(e) =>
                          handleUpdateTaxProfileData(
                            tax.externalId,
                            "taxRate",
                            e.target.value
                          )
                        }
                        type="number"
                        placeholder={"Tax amount"}
                      />
                    </TableCell>
                    <TableCell>
                      <MultipleSelect
                        value={tax.compoundTaxReferences}
                        options={tax.allowedReferences.map((ref, i) => ({
                          label:
                            ref.taxName || ref.taxName !== "" ? (
                              ref.taxName
                            ) : (
                              <i>(Unnamed Tax) {i + 1}</i>
                            ),
                          value: ref.externalId,
                        }))}
                        onChange={(val) =>
                          handleUpdateTaxProfileData(
                            tax.externalId,
                            "compoundTaxReferences",
                            val
                          )
                        }
                      />
                    </TableCell>
                    {i !== 0 && (
                      <TableCell>
                        <IconButton color="error">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={submitLoading}
            variant="contained"
            onClick={handleSubmit}
          >
            Create
          </Button>
        </Grid>
      </Grid>
    </ContentContainer>
  );
}

export default TaxProfileEditor;
