import React from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectifMultiChainAdmin } from "../../redux/slices/authSlice.js";
import Catalog from "../Catalog";
import Inventory from "../Inventory/Inventory";
import GaurdedRoutes from "./GaurdedRoutes.js";
import CatalogOutlet from "../OutletAdminPages/Catalog";
import DealsOutlet from "../OutletAdminPages/deals";
import InventoryOutlet from "../OutletAdminPages/Inventory/index.v3";
import TypedOrdersOutlet from "../TypedOrders/index";
import Deals from "../Deals/Index.updated";
import TypedOrderDetail from "../TypedOrders/OrderDetail";
import Discounts from "../Discounts";
import TypedUserDetails from "../TypedUsers/userDetails";
import AddCouponsTajwar from "../Discounts/AddCouponTajwar";
import EditCouponTajwar from "../Discounts/EditCouponTajwar";
import Banner from "../Banner/Index";
import CreatePage from "../PrivacyPage/AddPrivacy";
import AllPage from "../PrivacyPage/Privacy";
import Categories from "../../components/Categories/Categories.v2";
import Brands from "../../components/Brands/Brands.v2";
import Dashboard from "../Dashboard";
import GeneralSettingsPage from "../GeneralSettings";
import TypedUsers from "../TypedUsers/index.v2";
import AddPoints from "../LoyaltyPoints/AddPoints";
import NotificationsIndex from "../Notifications/index-updated";
import ChatPage from "../Chat";
import SpecialCoupon from "../specialCoupon/SpecialCouponUpdated";
import AddSpecialCoupon from "../specialCoupon/Add-specialCouponUpdated";
import EditSpecial from "../specialCoupon/Edit-SpecialCouponUpdated";
import DiscountsOutlet from "../OutletAdminPages/discounts/couponUpdated";
import Loyalty from "../LoyaltyPoints/index-updated";
import SpecializePoint from "../LoyaltyPoints/SpecializePoint";
import Logout from "../Logout/Logout";
import ForgotPassword from "../../components/LoginPage/ForgotPassword";
import TypedDeals from "../TypedDeals/TypedDeals";
import TypedCoupons from "../TypedCoupons/TypedCoupons";
import AddTypedDealPage from "../TypedDeals/sub-pages/add-typed-deals";
import EditTypedDealPage from "../TypedDeals/sub-pages/edit-typed-deals";
import AddTypedCouponPage from "../TypedCoupons/sub-pages/add-typed-coupons";
import EditTypedCouponPage from "../TypedCoupons/sub-pages/edit-type-coupons";
import TypedNotifications from "../TypedNotifications/TypedNotifications";
import AddTypedNotificationPage from "../TypedNotifications/sub-pages/add-typed-notifications";
import EditTypedNotificationPage from "../TypedNotifications/sub-pages/edit-typed-notifications";
import PrefferencesSettings from "../TypedNotifications/prefferences/settings";
import TypedBanners from "../TypedBanners/TypedBanners";
import AddTypedBannerPage from "../TypedBanners/sub-pages/add-typed-banners";
import EditTypedBannerPage from "../TypedBanners/sub-pages/edit-type-banners";
import RedemptionPolicy from "../Loyalty/LoyaltyRules/sub-pages/RedemptionPolicy";
import AllCustomPages from "../CustomPages/AllCustomPages.jsx";
import CreateCustomPage from "../CustomPages/CreateCustomPage.jsx";
import EditCustomPage from "../CustomPages/EditCustomPage.jsx";
import ListTaxProfiles from "../TaxProfile/ListTaxProfiles.jsx";
import TaxProfileEditor from "../TaxProfile/TaxProfileEditor.jsx";
import DeliverySettings from "../DeliverySettings/index.js";

const isAdmin = JSON.parse(localStorage.getItem("Admin"));
export default function Routes() {
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);
  return (
    <Switch>
      <GaurdedRoutes
        path="/catalog"
        component={Catalog}
        auth={isAdmin ? isAdmin[0].soleOutlet : false}
      />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/typed-users" component={TypedUsers} />
      <Route exact path="/typed-user-detail" component={TypedUserDetails} />
      <Route exact path="/inventory" component={Inventory} />
      <Route exact path="/typed-order-outlet" component={TypedOrdersOutlet} />
      <Route exact path="/typed-order-detail" component={TypedOrderDetail} />
      <Route exact path="/inventory-outlet" component={InventoryOutlet} />
      <Route exact path="/catalog-outlet" component={CatalogOutlet} />
      <Route exact path="/deals-outlet" component={DealsOutlet} />
      <Route exact path="/deals" component={Deals} />
      <Route exact path="/banner" component={Banner} />
      <Route exact path="/discounts" component={Discounts} />
      <Route exact path="/discounts-outlet" component={DiscountsOutlet} />
      <Route exact path="/add-coupon" component={AddCouponsTajwar} />
      <Route exact path="/edit-coupon" component={EditCouponTajwar} />
      <Route exact path="/add-page" component={CreatePage} />
      <Route exact path="/page" component={AllPage} />

      <Route exact path="/custom-pages" component={AllCustomPages} />
      <Route exact path="/custom-page/create" component={CreateCustomPage} />
      <Route exact path="/custom-page/edit/:id" component={EditCustomPage} />

      <Route exact path="/categories" component={Categories}></Route>
      <Route exact path="/brands" component={Brands}></Route>
      <Route exact path="/loyalty-points" component={Loyalty}></Route>
      <Route exact path="/chat" component={ChatPage} />
      {/* <Route exact path="/loyalty-add-points" component={AddPoints}></Route> */}
      <Route
        exact
        path="/redemption-policy"
        component={RedemptionPolicy}></Route>
      <Route
        exact
        path="/general-settings"
        component={GeneralSettingsPage}></Route>
      
      <Route exact path="/tax-profile" component={ListTaxProfiles}></Route>
      <Route exact path="/tax-profile/create" component={TaxProfileEditor}></Route>
      <Route exact path="/delivery-settings" component={DeliverySettings}></Route>
      <Route exact path="/ForgotPassword" component={ForgotPassword}></Route>
      <Route exact path="/my-notifications" component={NotificationsIndex} />
      <Route exact path="/Special-Day" component={SpecialCoupon} />
      <Route exact path="/Add-SpecialDay" component={AddSpecialCoupon} />
      <Route exact path="/Edit-SpecialEvent" component={EditSpecial} />
      <Route exact path="/Specializedloyalty" component={SpecializePoint} />
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/typed-deals" component={TypedDeals} />
      <Route exact path="/add-typed-deal" component={AddTypedDealPage} />
      <Route exact path="/edit-typed-deal" component={EditTypedDealPage} />
      <Route exact path="/typed-coupons" component={TypedCoupons} />
      <Route exact path="/add-typed-coupon" component={AddTypedCouponPage} />
      <Route exact path="/edit-typed-coupon" component={EditTypedCouponPage} />
      <Route exact path="/typed-notifications" component={TypedNotifications} />
      <Route
        exact
        path="/add-typed-notification"
        component={AddTypedNotificationPage}
      />
      <Route
        exact
        path="/edit-typed-notification"
        component={EditTypedNotificationPage}
      />
      <Route
        exact
        path="/notification-settings"
        component={PrefferencesSettings}
      />
      <Route exact path="/typed-banners" component={TypedBanners} />
      <Route exact path="/add-typed-banner" component={AddTypedBannerPage} />
      <Route exact path="/edit-typed-banner" component={EditTypedBannerPage} />
    </Switch>
  );
}
