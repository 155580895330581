/**
 * str: string, // The string that needs to be cut out
 * maxCharacters: int // Total allowed characters
 */
export const renderCappedString = (str, maxCharacters) => {
    if (str) {
        if (str.length > maxCharacters) {
            return str.substring(0, maxCharacters) + "..."
        } else {
            return str
        }
    }
    return str
}

export function updateObjectByKey (key, value, obj) {
    if (!obj) throw new Error('Please pass the object where the key needs to be changed as third argument');
    var newObj = { ...obj }
    const keyChain = key.split(".")
    const currentKey = keyChain[0]
    const lastKey = keyChain.length === 1
    var arrIndex = -1;
    var arrKey = null;
    if (currentKey.includes("[") && currentKey.includes("]")) {
        arrKey = currentKey.split("[")[0]
        arrIndex = parseInt(currentKey.split("[")[1].slice(0, -1));
    }
    if (keyChain.length === 0) return newObj
    if (newObj instanceof Object) {
        if (currentKey in newObj || arrKey in newObj) {
            if (arrIndex !== -1 && arrKey !== null) {
                if (lastKey) {
                    newObj[arrKey] = newObj[arrKey].map((o, i) => i === arrIndex ? value : o)
                    return newObj
                } 
                newObj = { 
                            ...newObj, 
                            [arrKey]: updateObjectByKey(keyChain.slice(1).join("."), value, newObj[arrKey][arrIndex]
                            )
                        
                }
            } else {
                if (lastKey) { 
                    newObj[currentKey] = value
                    return newObj
                }

                newObj = { ... newObj, [currentKey]: updateObjectByKey(keyChain.slice(1).join("."), value, newObj[currentKey]) }
                
            }
        }
        return newObj
    } else {
        return newObj
    }
}
export function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
    let result = '';
    const charactersLength = characters.length;
    
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    
    return result;
  }