import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material";

const InputPrimary = ({
  label,
  blankLabel,
  disabled = false,
  hasBorder = true,
  labelHasMargin = false,
  showValue,
  points,
  hintChip,
  mutiline,
  ...props
}) => {
  const theme = useTheme();

  // Event handler to prevent "+" and "-" characters and Enter key
  const handleKeyPress = (event) => {
    if (event.key === "+" || event.key === "-" || event.key === "Enter") {
      event.preventDefault();
    }
  };
  const handleWheel = (event) => {
    event.target.blur();
  };

  return (
    <Container
      theme={theme}
      disabled={disabled}
      hasBorder={hasBorder}
      labelMargin={labelHasMargin}
      hintChip={hintChip}
    >
      {blankLabel ? (
        <label>&nbsp;</label>
      ) : (
        <>
          <div style={{ display: "flex" }}>
            <label>{label}</label>
            {showValue === true ? (
              <span
                style={{
                  marginLeft: "10px",
                  color: "#8b8b8b",
                }}
              >
                ({points ? points : 1} point = $1)
              </span>
            ) : null}
          </div>
        </>
      )}
      <div className="primary-input-container">
        {mutiline ? (
          <textarea
            disabled={disabled}
            onKeyPress={handleKeyPress}
            onWheel={handleWheel}
            {...props}
          >{props.value}</textarea>
        ) : (
          <input
            disabled={disabled}
            onKeyPress={handleKeyPress}
            onWheel={handleWheel}
            {...props}
          />
        )}
        {/* <input
          disabled={disabled}
          onKeyPress={handleKeyPress}
          onWheel={handleWheel}
          {...props}
        /> */}
        {hintChip && (
          <div className="hint-chip" theme={theme}>
            {hintChip}
          </div>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  label {
    color: #747474;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    display: block;
    margin-top: ${(props) => (!props.labelMargin ? "0px" : "30px")};
  }
  .primary-input-container {
    position: relative;
    textarea, input {
      display: block;
      width: 100%;
      outline: none;
      border: ${(props) => {
        return props.hasBorder ? "1px solid #e2e8f0" : "none";
      }};
      background: ${(props) =>
        props.disabled ? props.theme.palette.gray.disabled : "transparent"};
      font-size: 0.938rem;
      box-sizing: border-box;
      padding: 12px 16px;
      border-radius: 5px;
      ${(props) => props.hintChip && `padding-left: 48px;`}
    }
    .hint-chip {
      position: absolute;
      left: 8px;
      top: 8px;
      bottom: 8px;
      aspect-ratio: 1/1;
      background-color: ${(props) => props.theme.palette.secondary.main};
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: white;
      border-radius: 5px;
    }
  }
`;
export default InputPrimary;
